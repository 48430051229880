<template>
  <el-card shadow="never" style="padding-bottom: 150px">
    <el-form :model="formData" label-width="200px">
      <el-row>
        <el-form-item label="入库类型：">
          <el-select v-model="formData.changeType" class="form-item" @change="typeChange" placeholder="请选择入库类型">
            <el-option label="采购入库" value="purchase"></el-option>
            <el-option label="百福回收入库" value="recycle_bf"></el-option>
            <el-option label="厂内回收入库" value="recycle_inner"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row v-if="formData.changeType==='purchase'">
        <el-form-item label="入库库区：">
          <el-select v-model="formData.positionCode" class="form-item" placeholder="请选择入库库区">
            <el-option label="包材新品库" value="new"></el-option>
            <el-option label="包材半成品库" value="semi"></el-option>
            <el-option label="耗材库" value="consumable"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="包材编码：">
          <el-select v-model="formData.packMaterialCode" class="form-item" filterable clearable placeholder="请选择包材编码">
            <el-option v-for="item in packMaterialCodeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row v-if="isRecycle">
        <el-form-item label="入库库区：">
          <el-select v-model="formData.positionCode" disabled class="form-item">
            <el-option label="包材待回收库" value="recycle"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-form-item label="入库数量：">
        <el-input-number v-model="formData.stockAmount" :min="1" :step="1" step-strictly class="form-item"></el-input-number>
      </el-form-item>

      <el-row>
        <el-col :span="5" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">提交入库</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "InStore",
  components: {MessageComponent},
  data() {
    return {
      formData: {
        changeType: '',
        positionCode: '',
        packMaterialCode: '',
        stockAmount: undefined
      },
      packMaterialCodeList: [],
      loading: false
    }
  },
  computed: {
    isRecycle() {
      return ['recycle_inner', 'recycle_bf'].includes(this.formData.changeType)
    }
  },
  created() {
    this.$axios.post('packMaterial/getAllPackMaterial').then(response => {
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error('获取包材编码数据源失败，' + res.message);
      }
      this.packMaterialCodeList = res.data || []
    }, error => {
      this.$message.error('获取包材编码数据源失败，' + error.message);
    })
  },
  methods: {
    typeChange() {
      if (this.isRecycle) {
        this.formData.positionCode = 'recycle'
      } else {
        this.formData.positionCode = ''
      }
    },
    submit() {
      this.$refs.commitMessage.clear();
      if (!this.formData.changeType) {
        return this.$refs.commitMessage.showError("请选择入库类型");
      }
      if (this.formData.changeType === 'purchase') {
        if (!this.formData.positionCode) {
          return this.$refs.commitMessage.showError("请选择入库库区");
        }
        if (!this.formData.packMaterialCode) {
          return this.$refs.commitMessage.showError("请选择包材编码");
        }
      }

      if (!this.formData.stockAmount || this.formData.stockAmount < 1) {
        return this.$refs.commitMessage.showError("请输入入库数量");
      }

      this.loading = true
      this.$axios.post('storeAmount/inStore', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        this.$refs.commitMessage.showSuccess('提交成功');
        this.formData.packMaterialCode = ''
        this.formData.stockAmount = undefined
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      })

    }
  }
}
</script>

<style scoped>
.form-item {
  width: 250px;
}
</style>